define("discourse/plugins/poll/discourse/components/poll-results-sankey", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/template", "ember-modifier", "discourse/lib/load-script", "discourse/plugins/poll/lib/chart-colors", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _template, _emberModifier, _loadScript, _chartColors, _pollUiBuilder, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PollResultsSankeyComponent extends _component.default {
    registerCanvasElement = (() => (0, _emberModifier.modifier)(element => {
      this.canvasElement = element;
    }))();
    get canvasId() {
      return (0, _template.htmlSafe)(`poll-results-chart-${this.args.id}`);
    }
    async drawSankey() {
      await (0, _loadScript.default)("/javascripts/Chart.min.js");
      await (0, _loadScript.default)("/plugins/poll/chartjs/chartjs-chart-sankey.min.js");
      const el = this.canvasElement;
      var labels = this.args.rankedChoiceOutcome.sankey_data.sankey_labels;
      function getColor(palette, name) {
        const node_id = name.split('_')[0];
        const item = palette.find(obj => obj.id === node_id);
        return item.color || "green";
      }
      this._chart = new Chart(el, {
        type: "sankey",
        data: {
          datasets: [{
            data: this.args.rankedChoiceOutcome.sankey_data.sankey_nodes,
            labels: labels,
            colorFrom: c => getColor(this.args.rankedChoiceOutcome.sankey_data.sankey_colours, c.dataset.data[c.dataIndex].from),
            colorTo: c => getColor(this.args.rankedChoiceOutcome.sankey_data.sankey_colours, c.dataset.data[c.dataIndex].to),
            borderWidth: 2,
            borderColor: 'black'
          }]
        }
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "drawSankey", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="poll-results-chart">
          <canvas
            {{didInsert this.drawSankey}}
            {{didInsert this.registerCanvasElement}}
            id={{this.canvasId}}
            class="poll-results-canvas"
          ></canvas>
        </div>
      
    */
    {
      "id": "gwlLwGQF",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"poll-results-chart\"],[12],[1,\"\\n      \"],[11,\"canvas\"],[16,1,[30,0,[\"canvasId\"]]],[24,0,\"poll-results-canvas\"],[4,[32,0],[[30,0,[\"drawSankey\"]]],null],[4,[32,0],[[30,0,[\"registerCanvasElement\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-results-sankey.js",
      "scope": () => [_didInsert.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollResultsSankeyComponent;
});